@font-face {
  font-family: Bebas Neue;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  /* src: url(../media/bebas-neue-latin-ext-400-normal.99be330d826b8dba922c.woff2)
      format("woff2"),
    url(../media/bebas-neue-latin-ext-400-normal.40ecee6e5fd01923295f.woff)
      format("woff"); */
}

:root {
  --primary: #4939a7;
  --secondary: #33267c;
  --text: #3a3535;
  --gray: #ccc;
  --white: #fff;
  --black: #000;
  --red: red;
  --darkred: #c30000;
  --green: green;
  --darkgreen: #024202;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main--div--dashboard {
  height: 100vh;
  background: var(--white);
  overflow: auto;
}

body {
  font-family: "Bebas Neue", sans-serif;
  /* background: linear-gradient(45deg, #e7fffa, #c5e0f5); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.container--custom {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

/* common */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.bg--primary {
  background-color: var(--primary);
  color: var(--white);
  transition: 0.3s;
}

.bg--primary:hover {
  background-color: var(--secondary);
  color: var(--white);
}

.bg--white {
  background-color: var(--white);
  color: var(--text);
}

.bg--success {
  background-color: var(--green);
  color: var(--white);
  transition: 0.3s;
}
.bg--success:hover {
  background-color: var(--darkgreen);
  color: var(--white);
}

.bg--red {
  background-color: var(--red);
  color: var(--white);
  transition: 0.3s;
}

.bg--red:hover {
  background-color: var(--darkred);
  color: var(--white);
}

.text-center {
  text-align: center;
}
.text-none {
  text-decoration: none;
}

.flex {
  display: flex;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.gap-5 {
  gap: 1.25rem;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.m-auto {
  margin: 0 auto;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.w-m-full {
  max-width: 100% !important;
}

.w-m-1700 {
  max-width: 1700px !important;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-fit-content {
  width: fit-content;
}

.relative {
  position: relative !important;
}

.transform-none {
  transform: none !important;
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

/* common end */

/* header */
.header--top {
  background-color: var(--primary);
}

.header--top h1 {
  color: var(--white);
  letter-spacing: 1px;
}

/* header end */

.btn--common {
  box-sizing: border-box;
  height: 40px;
  max-width: 150px;
  width: 100%;
  padding: 5px 20px;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  font-family: sans-serif;
}

.custom--form {
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f8f8f8;
  max-width: 400px;
  border-radius: 10px;
  padding: 35px;
  -webkit-box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  border: 1px solid #efefef;
  padding-top: 0px;
}

.custom--form > h1 {
  font-size: clamp(20px, 3vw, 30px);
  margin-top: -20px;
  text-transform: uppercase;
  background: #f8f8f8;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  border: 1px solid #efefef;
  min-height: 60px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101010;
  letter-spacing: 1px;
}
.note--text {
  font-family: none;
  text-align: right;
  color: var(--red);
  font-size: 18px;
  font-weight: 600;
}

.box--list {
  padding: 20px;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  border: 1px solid #efefef;
}

.custom--form > form > .form--group,
.custom--form > form > .box--list > .form--group,
.custom--form > .enter--input--form > .box--list > .form--group {
  margin-bottom: 15px;
}

.custom--form > form > .form--group > label,
.custom--form > .enter--input--form > .box--list > .form--group > label {
  margin-bottom: 7px;
  display: block;
  color: #2e2e2e;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-family: sans-serif;
}

.custom--form > form > .form--group > input,
.custom--form > .enter--input--form > .box--list > .form--group > input {
  width: 100%;
  border: none;
  border-radius: 10px;
  min-height: 40px;
  padding: 10px;
}

.custom--form > form > .form--group > input::placeholder,
.custom--form
  > .enter--input--form
  > .box--list
  > .form--group
  > input::placeholder {
  color: #cdcdcd;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  font-size: 12px;
}

.custom--form > form > .form--group > input:focus,
.custom--form > .enter--input--form > .box--list > .form--group > input:focus {
  outline: none;
  box-shadow: none;
}

.custom--form > form > .form--group > button,
.custom--form > .enter--input--form > .box--list > .form--group > button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  min-height: 40px;
  background: linear-gradient(45deg, var(--primary), #00ffba);
  color: #fff;
  margin-top: 10px;
  font-size: 15px;
  text-transform: uppercase;
}

.custom--form > form > .form--group > button,
.custom--form > .enter--input--form > .box--list > .form--group > button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  min-height: 40px;
  color: #fff;
  margin-top: 10px;
  font-size: 15px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}

.custom--form > form > .form--group > button:before,
.custom--form
  > .enter--input--form
  > .box--list
  > .form--group
  > button:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #00ffba, var(--primary));
  display: block;
  position: absolute;
  top: 0;
  left: 0px;
  border-radius: 10px;
  z-index: 1;
  opacity: 1;
  transition: 0.3s;
}

.custom--form > form > .form--group > button:hover:before,
.custom--form
  > .enter--input--form
  > .box--list
  > .form--group
  > button:hover:before {
  opacity: 0;
}

.custom--form > form > .form--group > button:after,
.custom--form > .enter--input--form > .box--list > .form--group > button:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, var(--primary), #00ffba);
  display: block;
  position: absolute;
  top: 0;
  left: 0px;
  border-radius: 10px;
  transition: 0.3s;
}

.custom--form > form > .form--group > button > span,
.custom--form
  > .enter--input--form
  > .box--list
  > .form--group
  > button
  > span {
  position: relative;
  display: block;
  z-index: 1;
}

.input--form--container {
  max-width: 800px;
}

.enter--input--form {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.next--btn > a:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 8px solid white;
  position: relative;
  display: inline-block;
  margin-left: 13px;
  display: block;
}

.next--btn:before,
.next--btn:after {
  opacity: 0 !important;
}

.next--btn > a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  min-height: 40px;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}

.next--btn {
  background: #00a3ff !important;
  width: 100%;
  border-radius: 10px;
  border: none;
  margin-top: 10px;
}

a.back--btn {
  padding: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  font-size: 17px;
  justify-content: flex-start;
}

a.back--btn:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 8px solid #008fff;
  position: relative;
  margin-right: 13px;
  display: block;
}

.col-3 {
  width: calc(24.333% - 10px);
}

.col-4 {
  width: calc(33.333333% - 10px);
}

.col-6 {
  width: calc(50% - 10px);
}

.col-12 {
  width: calc(100% - 10px);
}

.table--container {
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
}

.table--container tbody td {
  padding: 5px;
}

.table--container tbody td input {
  padding: 5px;
  height: 40px;
}

.table--container thead th {
  text-align: left;
  padding-bottom: 6px;
}

.table--inside table {
  box-sizing: border-box;
  position: relative;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  padding: 25px;
  -moz-box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  border: 1px solid #efefef;
  width: 100%;
}

.table--inside > h2,
.table--container > h2 {
  font-size: clamp(20px, 3vw, 30px);
  text-transform: uppercase;
  background: linear-gradient(45deg, var(--primary), #00ffba);
  border-radius: 10px 10px 0px 0px;
  -webkit-box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  min-height: 60px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 10px;
}

.table--container tbody td button {
  padding: 8px 10px;
  text-transform: uppercase;
  width: 110px;
  height: 40px;
  cursor: pointer;
}

.table--container tbody td button.update-button {
  background: #0086fd;
  border: #0086fd;
  border-radius: 5px;
  color: #fff;
}

.btn--list {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
}

.table--container tbody td button.delete-button {
  background: #ab0b0b;
  border: #ab0b0b;
  border-radius: 5px;
  color: #fff;
}

.table--container tbody td button.add-button {
  background: linear-gradient(45deg, var(--primary), #00ffba);
  border: #0086fd;
  border-radius: 5px;
  color: #fff;
}

.table_data {
  overflow: auto;
  padding-top: 50px;
  font-family: sans-serif;
}

.table_data input {
  height: 40px;
  border: 1px solid #ccc;
  width: 202px;
}

.table_data td {
  /* width: 75px; */
}

.table_data > table {
  /* width: 22px; */
  /* display: block; */
}

.table_data th {
  font-size: 15px;
  padding-bottom: 5px;
}

.table_data td .btn--list button {
  padding: 10px;
}

.table_data td .btn--list {
  gap: 5px;
}

.table_data td .btn--list button.update-button {
  background: var(--primary);
  color: #fff;
  border: none;
}

.table_data td .btn--list .delete-button {
  background: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}

.save_btn_change {
  text-align: center;
  padding-top: 20px;
}

.save_btn_change > button {
  padding: 10px;
  background: var(--primary);
  color: #fff;
  border: none;
  max-width: 146px;
  width: 100%;
  height: 45px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

/* Dashboard */
.data--item > button {
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  height: 40px;
  border: none;
  border-radius: 50px 0px 0px 50px;
}

.data--item > .item--1 {
  width: 100%;
  transition: 0.3s;
}

.data--item > .item--2 {
  max-width: 150px;
  width: 100%;
  background: var(--primary);
  color: var(--white);
  border-radius: 0px;
  text-transform: uppercase;
  transition: 0.3s;
  font-size: 12px;
  font-weight: 500;
}

.data--item {
  display: flex;
  gap: 0;
}

.container--list > .data--list {
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.data--item > .item--2:last-child {
  border-radius: 0px 50px 50px 0px;
  margin-left: 2px;
  background: var(--red);
  transition: 0.3s;
}

.data--item > .item--2:last-child:hover {
  background: #c30000;
}

.data--item > .item--2:hover {
  background: #281d6b;
}

.data--item > .item--1:hover {
  background: #e1e1e1;
}
.import--export--section {
  text-align: center;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: clamp(20px, 3vw, 50px);
}

.import--export--section .btn--common img {
  margin-right: 3px;
}

.import--export--section .btn--common {
  border-radius: 5px;
}
.import--export--section .btn--common.import--btn {
  background-color: #563cef;
}
.import--export--section input {
  opacity: 0;
  position: absolute;
}
.update--profile--div {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: clamp(20px, 2vw, 28px);
  background: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  border: 1px solid #e7e7e7;
}
.update--profile--div .form--group > label {
  margin-bottom: 7px;
  display: block;
  color: #2e2e2e;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-family: sans-serif;
}
.update--profile--div .form--group > input {
  width: 100%;
  border: none;
  border-radius: 10px;
  min-height: 40px;
  padding: 10px;
  margin-bottom: 10px;
}
.update--profile--div button {
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  margin-top: 5px;
}
.update--profile--div .col-6 {
  padding: 20px;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0px 3px 31px -7px rgba(235, 235, 235, 1);
  border: 1px solid #efefef;
}

.update--profile--div .col-6:first-child {
  box-shadow: none;
  background: transparent;
  border: none;
}

.update--profile--div .col-6:first-child input {
  background: #f8f8f8;
  border: 1px solid #ccc;
}

/* Dashboard end */

/* media queries */
@media (max-width: 991px) {
  .col-6,
  .col-3 {
    width: 100% !important;
  }

  .col-4 {
    width: 100%;
  }

  .custom--form.input--form--container {
    top: calc(50% + 165px);
  }

  .custom--form {
    padding: 15px;
  }

  .table_data input {
    width: 150px;
  }

  .add_new {
    display: block;
    padding-top: 20px;
  }

  .add_new tbody {
    display: block;
  }

  .add_new td {
    display: block;
    width: 100%;
  }

  .add_new tr {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  .add_new input {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .container--list > .data--list {
    row-gap: 15px;
  }

  .data--item {
    flex-wrap: wrap;
  }

  .data--item > .item--2 {
    border-radius: 50px 0px 0px 50px;
    max-width: 50%;
  }

  .data--item > .item--2:last-child {
    margin-left: 0px;
  }

  .data--item > .item--1 {
    border-radius: 50px;
  }
}

.export--data--add {
  margin-bottom: 30px;
  margin-top: -15px;
}
.export--data--add input::file-selector-button {
  font-weight: bold;
  color: #fff;
  padding: 0.5em;
  border-radius: 3px;
  background: var(--primary);
  border: var(--primary);
  border-radius: 0px !important;
  padding: 10px 15px;
}

.data--download--btn {
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
