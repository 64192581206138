/* Global Styles */
html,
body,
#root {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  font-family: "bebas neue", sans-serif;
  background-color: rgb(0, 0, 0);
  touch-action: none;
  /* Setting a background color for the entire page */
}

h5,
h6 {
  color: #ffffff;
}

.box {
  padding: 5px;
  width: 5rem;
  height: 6rem;
  background-color: #ffffff;
  border-radius: 15px;
}

.item-label {
  font-size: 0.67rem;
  font-weight: lighter;
}

.item-value {
  color: gray;
}

/* Add this CSS to your stylesheets or in a style tag in your component */

.slider-container {
  position: absolute;
  bottom: 3rem; /* Adjust the position as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 80%; /* Adjust the width as needed */
  z-index: 1; /* Ensure the slider is above the canvas */

  /* Optional: Add some styling to the container */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.slider {
  width: 100%;
  height: 10px; /* Adjust the height of the slider */
  background: linear-gradient(
    to right,
    #007bff,
    #ccc
  ); /* Gradient background */
  border-radius: 10px; /* Increase border-radius for a rounded look */
  appearance: none;
  outline: none;
  transition: background 0.3s; /* Add a smooth transition effect on hover */
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling on WebKit browsers */
  appearance: none;
  width: 20px; /* Adjust the width of the thumb */
  height: 20px; /* Adjust the height of the thumb */
  background: #007bff; /* Thumb color */
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s; /* Add a smooth transition effect on hover */
}

.slider::-moz-range-thumb {
  width: 20px; /* Adjust the width of the thumb for Firefox */
  height: 20px; /* Adjust the height of the thumb for Firefox */
  background: #007bff; /* Thumb color for Firefox */
  border: none; /* Remove border for Firefox */
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s; /* Add a smooth transition effect on hover */
}

/* Add a hover effect for the slider */
.slider:hover {
  background: linear-gradient(to right, #007bff, #007bff);
}

.slider::-webkit-slider-thumb:hover {
  background: #0044cc; /* Thumb color on hover */
}

.slider::-moz-range-thumb:hover {
  background: #0044cc; /* Thumb color on hover for Firefox */
}

.circle-text-container {
  width: 65px;
  height: 10px;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  transform: translateY(5px);
}

.circle-text-container > h6 {
  display: block;
  word-wrap: break-word;
  width: inherit;
  font-size: 8px;
  margin: 0px;
  height: fit-content;
}
